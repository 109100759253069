import {
  JOBS_API_ID,
  DEVICES_API_ID,
  FEEDBACK_API_ID,
  USERS_API_ID,
  GROUPS_API_ID,
  CREW_MEMBERS_API_ID,
  SITE_MAP_API_ID,
} from '_config/api-constants';

export const DISABLE_REDUX_LOGS = true;

export default {
  Auth: {
    // Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-east-2:17f6467f-bc51-429d-8bf1-c7281336151a',

    // Amazon Cognito Region
    region: 'us-east-2',

    // Amazon Cognito User Pool ID
    userPoolId: 'us-east-2_PaBnoYZnI',

    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '3040e9seikbi9i0kbgteuc8p7b',

    oauth: {
      domain: 'prod-bazooka-farmstar.auth.us-east-2.amazoncognito.com',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://www.farmstar-ecs.com',
      redirectSignOut: 'https://www.farmstar-ecs.com',
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  API: {
    endpoints: [
      {
        name: JOBS_API_ID,
        endpoint: "https://yn1bze23wd.execute-api.us-east-2.amazonaws.com/prod/v1",
        region: "us-east-2",
      },
      {
        name: DEVICES_API_ID,
        endpoint: "https://124d624yz5.execute-api.us-east-2.amazonaws.com/prod/v1",
        region: "us-east-2",
      },
      {
        name: FEEDBACK_API_ID,
        endpoint: "https://txru4p3xb4.execute-api.us-east-2.amazonaws.com/prod/v1",
        region: "us-east-2",
      },
      {
        name: USERS_API_ID,
        endpoint: "https://pzmjdkw7y4.execute-api.us-east-2.amazonaws.com/prod/v1",
        region: "us-east-2",
      },
      {
        name: GROUPS_API_ID,
        endpoint: "https://nk78auv3si.execute-api.us-east-2.amazonaws.com/prod/v1",
        region: "us-east-2",
      },
      {
        name: CREW_MEMBERS_API_ID,
        endpoint: "https://mzc0ktra4h.execute-api.us-east-2.amazonaws.com/prod/v1",
        region: "us-east-2",
      },
      {
        name: SITE_MAP_API_ID,
        endpoint: "https://jxtjrmakd2.execute-api.us-east-2.amazonaws.com/prod/v1",
        region: "us-east-2",
      },
    ]
  },
  PubSub: {
    aws_pubsub_region: "us-east-2",
    aws_pubsub_endpoint: "wss://a35mw6n5f5rsbj-ats.iot.us-east-2.amazonaws.com/mqtt",
  },
};
